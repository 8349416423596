<template>
    <div class="page-box">
        <headBar ref="headerHeight" title="超鹿自助健身卡" left-arrow @click-left="newAppBack">
          <template slot="right">
            <img @click="showShare = true" class="share-icon" src="https://img.chaolu.com.cn/ACT/invite-2022/share-icon.png" alt="">
          </template>
        </headBar>
        <app-page id="box" class="page-img">
            <van-sticky :offset-top="headerHeight">
                <div class="city-nav-box">
                    <div @click="cityChange(item)" :class="{active: item.cityId === currentCityId}" v-for="(item, index) in cityList" :key="index">
                        {{item.areaName}}卡
                    </div>
                </div>
            </van-sticky>
            <div class="page-h" v-if="selectItem.equityCardId">
                <div class="col-between-start" :style="`background-image: url(${cardInfo.backgroundImgUrl})`">
                    <div>{{cardInfo.equityCardName}}</div>
                    <!--<p v-if="selectItem.equityCardItemValidity"><span>有效期至{{selectItem.equityCardItemValidity}}{{ selectItem.isAutoRenew === 1 ? '（自动续费）' : '' }}</span></p>-->


                    <p v-if="cardInfo.isHaveCard"><span>续费开卡顺延生效<span v-if="selectItem.openValidity">，超出{{ selectItem.openValidity }}天未进馆自动退款</span></span></p>
                    <p v-else><span>下次进馆生效<span v-if="selectItem.openValidity">，超出{{ selectItem.openValidity }}天未进馆自动退款</span></span></p>

                </div>
            </div>
            <div v-else-if="finished" class="page-h" style="padding: 0 4.266vw;box-sizing: border-box">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/empty_bg.png" alt="" style="width: 100%;height: 71.2vw;">
            </div>
            <div class="card-box" v-show="selectItem.equityCardId">
                <div class="new-card-list">
                    <div @click="selectCard(item)" class="card-item" :class="{active: item.equityCardItemId === selectItem.equityCardItemId}" v-for="(item, index) in cardInfo.equityCardItemVOList" :key="index">
                        <div class="row-between-end card-item-top">
                            <p class="ellipsis card-name">{{ item.equityCardItemName }}</p>
                            <div v-if="item.originalPrice" class="originalPrice">¥{{ item.originalPrice | priceFormat }}</div>
                        </div>
                        <div class="row-between-end">
                           <div class="col-start-start">
                               <div style="margin-bottom: 1.6vw" v-if="item.equityCardItemLightspot" class="item-tips ellipsis">{{ item.equityCardItemLightspot }}</div>
                               <!-- 券后标签 -->
                               <p v-if="item.discountPrice" class="row-center-center couponAfterPrice">
                                   券后&nbsp;¥{{item.discountPrice | priceFormat}}
                               </p>
                           </div>
                            <div class="price" v-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice"><span>¥ </span>{{ item.firstPrice | priceFormat}}</div>
                            <div class="price" v-else-if="item.salePrice"><span>¥ </span>{{ item.salePrice | priceFormat}}</div>
                        </div>
                        <!-- 专享价 -->
                        <p v-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice" class="first-price">新客专享价，不可转赠</p>
                        <!-- 右上角标签 -->
                        <span v-if="item.activeLabel" class="discount-tag row-center-center">{{ item.activeLabel }}</span>
                    </div>
                </div>
                <div v-if="checkOrder" class="order-card-tips">你有一笔待支付自助订单，购买自助卡可直接抵扣免单</div>
                <div class="card-tips">{{selectItem.buyKnow}}</div>


                <div v-if="selectItem.equityCardId" class="card-box bottom-btn">
                  <div v-if="selectItem.firstPrice" class="first-price-box" :class="{active: giveUpFirstBuy}" @click="changeGiveUpFirstBuy">自愿放弃新客优惠，使用原价购买。</div>

                    <div v-if="selectedCouponId && voucherList.length" class="select-voucher" @click="showCoupon = true">
                        <div class="row-start-center" style="white-space: nowrap"><img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips.png" alt="">已减 <span>{{ selectedDiscount }}元</span>
                            <span class="row-start-center">，
                             <van-count-down @finish="timeFinish" :time="selectedCouponTips">
                              <template #default="timeData">
                                <span style="font-size: 3.2vw;line-height: 4.27vw;white-space: nowrap;">{{ timeData.days }}天{{ timeData.hours }}时{{ timeData.minutes }}分</span>
                              </template>
                            </van-count-down>
                            </span>后失效</div>
                        <p><span>{{voucherList.length}}张</span><van-icon size="12px" name="arrow" /></p>
                    </div>
                    <div v-else-if="voucherList.length" class="select-voucher" @click="showCoupon = !(selectItem.firstPrice && !giveUpFirstBuy)">
                        <div class="row-start-center"><img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips1.png" alt=""><span class="fw6">我的优惠券</span></div>
                        <p v-if="selectItem.firstPrice && !giveUpFirstBuy">不可与新客优惠同享</p>
                        <p v-else>{{voucherList.length}}张<van-icon name="arrow" /></p>
                    </div>

                    <div class="buy-card-btn row-between-center" :class="{'send-bg': (selectedCouponId || selectItem.firstPrice)}">
                        <h4>
                            <span class="s1">¥&nbsp;&nbsp;</span><span class="BebasNeueBold">{{salePrice | priceFormat}}</span>
                        </h4>
                        <div>
                            <p @click="checkAwaitOrder()" class="row-center-center no-fast-click"></p>
                        </div>
                    </div>
                    <div class="protocol" :class="{shake: shake}">
                        <p @click="protocol = !protocol" :class="{active: protocol}"></p>
                        <div><span @click="protocol = !protocol">点击购买支付即代表您已阅读并同意</span><span class="s1" @click.stop="checkProtocol(true)">《超鹿运动自助卡购买服务协议》</span></div>
                    </div>
                </div>
                <div class="gift-detail" v-if="selectItem.equityCardEquityVOList && selectItem.equityCardEquityVOList.length">
                    <div class="gift-detail-top">
                        <h4>购卡可得权益包</h4>
                        <div class="row-start-start">
                            <div v-for="(item, index) in selectItem.equityCardEquityVOList" :key="index">
                                <span v-if="item.equityLabel && item.equityLabel!=='-'" class="row-center-center">{{ item.equityLabel }}</span>
                                <img :src="item.equityIconUrl" alt="">
                                <p>{{ item.equityName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="gift-detail-bottom">
                        <h4>权益详细说明</h4>
                        <p>*权益每30天解锁发放*</p>
                        <div class="gift-detail-item row-start-start" v-for="(item, index) in selectItem.equityCardEquityVOList" :key="index">
                            <span class="gift-sort row-center-center">权益{{ chinaNum[index] }}</span>
                            <img :src="item.equityIntroduceImgUrl" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div  v-if="selectItem.equityCardId" class="bottom-rule">
                <h4>自助健身卡规则及权益说明</h4>
                <p>{{ activityRule }}</p>
            </div>
        </app-page>
        <!--支付成功弹窗-->
        <van-popup v-model="showPaySuccess" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png"
                        alt="">
                    <div>购买成功</div>
                </div>
                <!-- 用券购买，无赠送亲友 -->
                <div v-if="isUsedCoupon" class="pay-share-btn row-between-center">
                    <div class="flex-auto" @click="jumpMyBagCopy()">去我的卡包查看</div>
                </div>
                <div v-else class="pay-share-btn row-between-center">
                    <div @click="jumpMyBagCopy()">立即查看</div>
                    <div @click="jumpSendGift()">赠送亲友</div>
                </div>

                <div class="pay-box-tips">{{`会员卡已到账至 “个人中心-${ isUsedCoupon ? '卡包' : '自助卡' }”`}}</div>
            </div>
            <img @click="showPaySuccess = false" class="result-close"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>
        <!--错误弹窗提示-->
        <van-popup v-model="showError" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
           <div class="error-box">
               <h4>提示</h4>
               <p>{{errorMsg.msg}}</p>
               <div @click="closeError" class="row-center-center">我知道了</div>
           </div>
        </van-popup>
        <!--城市确认-->
        <van-popup v-model="showCheckCity" :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
            <div class="error-box">
                <h4>提示</h4>
                <p>您当前购买的自助健身卡，仅限<span style="font-weight: bold;color: #ED5C42">{{ cityName }}</span>所有门店使用，请确认后再付款！</p>
                <div @click="checkBuy" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!-- 协议弹窗 -->
        <van-popup v-model="showProtocol" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="protocol-box">
                <h4>“超鹿运动”自助卡服务协议</h4>
                <p>{{ protocolRule }}</p>
                <div v-if="doneProtocol" @click="showProtocol = false" class="row-center-center">我知道了</div>
                <div v-else class="row-center-center gray">请认真阅读以上内容({{ timeProtocol }})</div>
            </div>
        </van-popup>
        <!--自动支付弹窗-->
        <van-popup round v-model="showAutoPay" position="bottom" closeable :close-on-click-overlay="false">
            <div class="refund-box">
                <div class="refund-title">自动续费签约</div>
                <div class="refund-content">自动续费项目需要进行两笔支付签约，签约完成后再进行付款完成购买哦</div>
                <div class="pay-img">
                    <img v-if="isFirstContract" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/auto_pay1.png" alt="">
                    <img v-else src="https://img.chaolu.com.cn/ACT/sell-membership-2022/auto_pay2.png" alt="">
                </div>
                <div v-if="!isFirstContract" class="refund-info row-between-center">
                    <span>购买内容：</span>
                    <span>{{ selectItem.equityCardItemName }}(自动续费)</span>
                </div>
                <div v-if="!isFirstContract" class="refund-info  row-between-center">
                    <span>价格：</span>
                    <span>¥{{ selectItem.salePrice }}</span>
                </div>
                <div class="refund-btn row-between-center">
                    <div v-if="isFirstContract" @click="autoRenew()">完成第1笔签约</div>
                    <div v-else @click="autoRenew()">完成第2笔签约并支付</div>
                </div>
            </div>
        </van-popup>
        <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl" @payComplete="payComplete"></payPopupCard>
        <coupon :dateTips="selectedCouponTips" :unavailableList="unavailableList" :discount="selectedDiscount" :couponId="selectedCouponId" :list="voucherList" @confirm="confirmVoucher" :showCoupon.sync="showCoupon"></coupon>
        <!--  分享  -->
        <common-share
            :value="showShare"
            :shareParams="shareParams"
            :shareItem="['minifriend']"
            @close="showShare = false"
            @share-success="showShare = false"
        >
        </common-share>
    </div>
</template>
<script>
import userMixin from '@/mixin/userMixin';
import commonShare from "@/components/commonShare"
import headBar from '@/components/app/headBar'
import appPage from "@/common/components/appPage"
import payPopupCard from "@/components/pay-popup-card"
import coupon from "@/components/pay-popup-card/coupon"
import {
    appPay,
    appPaySuccess,
    initBack,
    newAppBack,
    appAutoRenewPay,
    enterForeground,
    getAppVersion, silentAppPay, gotoOrderList
} from "@/lib/appMethod";
import wx from "weixin-js-sdk"
import {compareVersion, getFormatDate} from "@/lib/utils";
import {activityRule, protocol} from "@/views/month-card/const";
export default {
    data() {
        return {
            chinaNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
            time: 0,
            activityStatus: 0, // 活动状态 1 活动未开始 2 活动进行中 3 活动已结束
            showShare: false,
            ruleShow: false,
            giveUpFirstBuy: false,
            shareParams: {
                title: '健身就买超鹿自助健身卡，享超值福利。',
                miniImage: 'https://img.chaolu.com.cn/MINI/minicard/8.png',
                path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership/self',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            userPoster: '',
            headImg: '',
            nickName: '',
            showCoupon: false,
            showPaySuccess: false,
            showRule: false,
            showAutoPay: false,
            showCheckCity: false, // 城市确认弹窗
            isShowCheckCityName: '', // 弹出城市确认弹窗的城市
            type: 0,
            selectItem: {}, // 当前选择卡下标
            cardInfo: {
                equityCardItemVOList: []
            },
            param: {},
            canIUse: true, // 当前版本是否可用
            protocol: false, // 勾选用户协议
            showProtocol: false, // 勾选用户协议
            doneProtocol: false,//已阅读完协议
            timeProtocol:  window.location.origin.indexOf('web.chaolu.com') > -1 ? 6 : 1, // 阅读倒计时
            currentCityId: '3', // 默认福州
            cityName: '福州市',
            showChoseCity: false,
            isFirstContract: true,
            showError: false,
            shake: false,
            errorMsg: {},
            finished: false,
            activityRule:activityRule,
            protocolRule: protocol,
            openCardItem: [],
            cityList: [],
            headerHeight: 0,
            showPayPopup: false,
            payData: {},
            payUrl: '',
            voucherList: [],
            unavailableList: [], //不可用列表
            selectedDiscount: 0,
            selectedCouponId: '',
            isUsedCoupon: 1, // 当前这笔支付是否用券
            selectedCouponTips: '', // 失效提示
            appVersion: 0, // app版本号
            canIUseSilentAppPay: false, // 能否使用web弹窗支付
            // canIUseGiveCard: false, // 能否使用赠卡功能
            topPrice: 0, // 最高立减
            activeList: [], // 可用卡列表
            ticketSource: 'WEB', // 购买来源 WEB 首页主动进入  SMS 短信进入
        };
    },
    mixins: [userMixin],
    components: {
        appPage,
        headBar,
        payPopupCard,
        coupon,
        commonShare
    },
    filters: {
        priceFormat(v) {
            if(!v){
                return 0
            }
            return parseFloat(v)
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.showShare = false
                console.log(7)
                if (this.userId && this.token) {
                    // this.getActivityInfo();
                }
            } else if (document.visibilityState === "hidden") {
                this.showShare = false
                this.showPaySuccess = false
                console.log(6)
            }
        })
        if(this.appTypeStr !== 'mini'){
            setTimeout(() => {
                this.headerHeight = this.$refs.headerHeight.$el.clientHeight || 0
            }, 1500)
        }
    },
    computed: {
        checkOrder() {
            return this.openCardItem.some(v => {
                return v.equityCardItemId === this.selectItem.equityCardItemId
            })
        },
        salePrice() {
            if(!this.giveUpFirstBuy && this.selectItem.firstPrice){
                return this.selectItem.firstPrice
            }
            // 不选券使用限时售卖价
            if(!this.selectedDiscount || !this.selectedCouponId){
                console.log(this.selectItem.salePrice)
                return this.selectItem.salePrice
            }
            // 最终售价
            let p = this.selectItem.salePrice || 0
            let voucherAfter = this.selectedDiscount || 0
            let price = (p - voucherAfter) < 0? 0: (p - voucherAfter)
            return price.toFixed(2)
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        this.currentCityId = await this.$getCityId() || '3'
        if(this.$route.query.venueId){
            this.currentVenueId = this.$route.query.venueId
            let r = await this.getVenueInfo()
            if(r.cityId){
                this.currentCityId = r.cityId
                this.cityName = r.cityName
            }
        }
        this.currentCityId = this.$route.query.cardCityId || this.currentCityId
        if(this.$route.query.ticketSource){
            this.ticketSource = this.$route.query.ticketSource
        }
        this.getCityList()
        console.log(this.currentCityId)
        this.getCardList(true)
        this.findNoPayTicket()
        // APP 切换到前台 监听是否再次弹起续费
        enterForeground(() => {
            let autoRenew = sessionStorage.getItem('autoRenew')
            console.log('app回前台', autoRenew)
            console.log(4)
            if(autoRenew){
                console.log('11111')
                console.log(5)
                this.checkAuto()
            }
        })
        if(this.appTypeStr === 'mini'){
            appPaySuccess().then(res=>{
                this.showPaySuccess = true
                this.isUsedCoupon = this.$route.query.isUsedCoupon
            })
        }
        try {
            this.appVersion = await getAppVersion()
            this.canIUse = (compareVersion(this.appVersion, '2.20.11') >= 0)
            this.canIUseSilentAppPay = (compareVersion(this.appVersion, '2.31.22') >= 0)
            // this.canIUseGiveCard = (compareVersion(this.appVersion, '2.32.22') >= 0)
        }catch (e){
            this.canIUse = false
            this.canIUseSilentAppPay = false
            // this.canIUseGiveCard = false
            this.appVersion = 0
        }
        console.log('当前版本',this.appVersion)
        this.clickId = this.$route.query.clickId
        this.addUserAction('CONFIRM_EFFECTIVE_LEADS')

        const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership/self`)
        this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
        wx.miniProgram.postMessage({
            data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
            },
        })
    },
    methods: {
        newAppBack,
        closeError() {
            this.showError = false
            if(this.errorMsg.skipOrder){
                gotoOrderList()
            }
        },
        confirmVoucher(item) {
            if(item){
                this.giveUpFirstBuy = true
                this.selectedDiscount = item.discount || 0
                this.selectedCouponId = item.id || ''
                let time = Math.round(item.expireDayTime*1000 - (new Date().getTime())) || 0
                this.selectedCouponTips = time < 0? 0: time
            }
        },
        changeGiveUpFirstBuy() {
            this.giveUpFirstBuy = !this.giveUpFirstBuy
            if(!this.giveUpFirstBuy){
                this.selectedDiscount = 0
                this.selectedCouponId = ''
                this.selectedCouponTips = 0
            }
        },
        payComplete(v) {
          this.isUsedCoupon = v
        },
        // 获取我的优惠券最高金额  及 可用和不可用列表
        getVoucherList(cardItemId) {
            this.$axios.post(`${this.baseURLApp}/voucher/equityCardVoucher`,  {
                userId: this.userId,
                token: this.token,
                cardItemIds: [cardItemId],
            }, false).then(res => {
                if( res.data) {
                    let data = res.data?.list[0] ?? {}
                    this.voucherList = data.availableList || []  // 可用列表
                    this.unavailableList = data?.unavailableList || [] // 不可用列表
                    // 如果有首约价就不选择优惠券
                    if(!this.giveUpFirstBuy && this.selectItem.firstPrice){
                        this.selectedDiscount = 0
                        this.selectedCouponTips = 0
                        this.selectedCouponId = ''
                        return false
                    }
                    this.selectedDiscount = data?.maxDiscount || 0
                    this.selectedCouponId = data?.maxDiscountVoucherId || ''
                    let time = Math.round(data?.expireDayTime*1000 - (new Date().getTime())) || 0
                    this.selectedCouponTips = time < 0? 0: time
                }
            })
        },
        // 倒计时结束刷新优惠券
        timeFinish() {
            // this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
        },
        findNoPayTicket() {
            this.$axios.post(`${this.baseURLApp}/ticket/findNoPayTicket/v2`,{
                userId: this.userId,
                token: this.token,
            }, false).then(res => {
                if(res.data.haveNoPayTicket === '1'){
                    this.ticketId = res.data.ticketId
                    this.getEquityCard(res.data.ticketId, res.data.userType, res.data.venueId)
                }

            })
        },
        getEquityCard(ticketId, userType, venueId) {
            if(!this.ticketId){
                return false
            }
            this.$axios.post(`${this.baseURLApp}/user/equityCard/orderPage/show`,{
                userId: this.userId,
                token: this.token,
                ticketId,
                userType,
                venueId
            }, false).then(res => {
                let dataInfo = res.data || {}
                try {
                    if(!this.isEmpty(dataInfo.equityCardOrderPageVOList) && !this.isEmpty(dataInfo.equityCardOrderPageVOList[0])){
                        this.openCardItem = dataInfo.equityCardOrderPageVOList[0].equityCardItemOrderPageVOList
                        console.log(this.openCardItem)
                    }
                }catch (e) {
                    this.openCardItem = []
                }
            })
        },
        getVenueInfo() {
            return new Promise(resolve => {
                this.$axios.post(`${this.baseURLApp}/newVenue/getVenueInfo`,{
                    userId: this.userId,
                    token: this.token,
                    venueId: this.currentVenueId
                }).then(res => {
                    resolve({cityId: res.data.cityId, cityName: res.data.cityName})
                }).catch(() => {
                    resolve({cityId: '3', cityName: "福州市"})
                })
            })
        },
        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, {userId: this.userId}).then(res => {
                this.cityList = res.data
                let item = this.cityList.find(r => {return r.cityId === this.currentCityId})
                if(item){
                    this.cityName = item.areaName
                    this.currentCityId = item.cityId
                }
            })
        },
        cityChange(v) {
            this.cityName = v.areaName
            this.currentCityId = v.cityId
            this.giveUpFirstBuy = false
            this.getCardList()

            // 修改分享参数
            const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership/self`)
            this.shareParams.path = `/pages/webView/index?cardCityId=${this.currentCityId}&webUrl=${uu}`
            wx.miniProgram.postMessage({
              data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
              },
            })
        },
        getCardList(isFirst) {
            this.$toast.loading({
                forbidClick: true,
                loadingType:'spinner',
                duration: 0,
                message: '加载中...'
            });
            this.$axios.post(`${this.baseURLApp}/equityCard/list`,{
                userId: this.userId,
                token: this.token,
            }).then(res => {
                this.finished = true
                this.$toast.clear()
                if(res.data[this.currentCityId]){
                    this.cardInfo = res.data[this.currentCityId][0] || {}
                    this.selectItem = this.cardInfo.equityCardItemVOList.length? this.cardInfo.equityCardItemVOList[0]: {}
                }else if(isFirst && res.data['3']){
                    this.currentCityId = '3'
                    this.cityName = '福州市'
                    // 首次加载当前城市没有，默认到福州
                    this.cardInfo = res.data['3'][0] || {}
                    this.selectItem = this.cardInfo.equityCardItemVOList.length? this.cardInfo.equityCardItemVOList[0]: {}
                }else{
                    this.selectItem = {}
                    this.cardInfo = {}
                }
                console.log(this.selectItem.equityCardItemId, this.selectItem.originalPrice)
                this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
                this.$forceUpdate()
            }).catch(() => {
                this.finished = true
                // this.$toast.clear()
            })
        },
        async selectCard(v) {
            this.selectItem = v
            this.giveUpFirstBuy = false
            await this.getVoucherList(v.equityCardItemId) // 请求用户的优惠券
        },
        // 校验是否有使用优惠券的待支付订单
        checkAwaitOrder() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/order/equityCardUser/monthlyCardToBePaidCouponVerification`, {
                userId: this.userId,
                userToken: this.token,
            }, false).then(res => {
                if(res.data) {
                    this.checkCity()
                    this.$toast.clear()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data?r.data.showAutoRenew:0}
                this.showError = true
                this.$toast.clear()
            })
        },
        // 校验是否阅读完月卡协议
        checkProtocol(show) {
            if(!this.doneProtocol){
                this.showProtocol = true
                let timer = setInterval(() => {
                    this.timeProtocol--
                    if(this.timeProtocol < 1){
                        clearInterval(timer)
                        this.doneProtocol = true
                        this.protocol = true
                    }
                }, 1000)
                return  false
            }else{
                if(show){
                    this.showProtocol = true
                }
                return true
            }
        },
        // 提示购买城市
        checkCity() {
            if(this.isShowCheckCityName && this.isShowCheckCityName === this.cityName){
                this.checkBuy()
                return false
            }
            this.showCheckCity = true
            this.isShowCheckCityName = this.cityName
        },
        // 校验是否可以买月卡
        checkBuy() {
            this.showCheckCity = false
            if(this.selectItem.isAutoRenew === 1 && this.appTypeStr === 'mini'){
                this.$toast('小程序暂不支持自动续费，请移步APP体验！')
                return false
            }
            if(this.selectItem.isAutoRenew === 1 && !this.canIUse){
                this.$toast('当前APP版本较低，请更新版本后重试！')
                return false
            }
            if(!this.checkProtocol()){
                return  false
            }
            if(!this.protocol){
                this.shake = true
                this.$toast({
                    message: '请仔细阅读并勾选购买协议！',
                    forbidClick: true,
                    onClose: () => {
                        this.shake = false
                    }
                })
                return false
            }
            let param = {
                equityCardItemId: this.selectItem.equityCardItemId,
                equityCardId: this.selectItem.equityCardId,
                userId: this.userId,
                token: this.token,
                source: 'SELF_BUY',
                giveUpFirstBuy: this.giveUpFirstBuy
            }
            this.$axios.post(`${this.baseURLApp}/equityCard/check/buy`,param, false).then(res => {
                if(res.data) {
                   this.buyCard()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data?r.data.showAutoRenew:0, skipOrder: r?.data?.skip === 'ORDER_CENTER'}
                this.showError = true
            })
        },
        // 周期支付
        autoRenew() {
            let info = this.selectItem
            sessionStorage.setItem('autoRenew', '1')
            appAutoRenewPay({
                equityCardItemId: info.equityCardItemId,
                equityCardId: info.equityCardId,
                userId: this.userId
            })
        },
        // 检查周期支付是否完成
        checkAuto() {
            this.$toast.loading({
                forbidClick: true,
                loadingType:'spinner',
                duration: 0,
                message: '加载中...'
            })
            let info = this.selectItem
            let param = {
                equityCardItemId: info.equityCardItemId,
                equityCardId: info.equityCardId,
                userId: this.userId
            }
            this.$axios.post(`${this.baseURLApp}/msCard/cyclePaySign/query`,param).then(res => {
                this.$toast.clear()
                let data = res.data || {}
                console.log(1)
                if(res.data.hasNextSign > 0) {
                    console.log(2)
                    this.showAutoPay = true
                    this.isFirstContract = (data.needSignAmount === data.totalAmount)
                }else if(sessionStorage.getItem('autoRenew') && data.hasSignedAmount === data.totalAmount){
                    this.showPaySuccess = true
                    console.log(3)
                    this.showAutoPay = false
                }
            }).catch(() => {
                this.$toast.clear()
            })
        },
        closePaySuccess() {
            sessionStorage.setItem('autoRenew', '')
        },
        // 购买
        buyCard(){
            // 点击购买页 上报
            this.addUserAction('COMPLETE_ORDER')
            if(this.selectItem.isAutoRenew === 1){
                this.checkAuto()
                return false
            }
            let info = this.selectItem
            this.isUsedCoupon = (this.selectedCouponId || (!this.giveUpFirstBuy && info.firstPrice)) ? 1 : 0
            let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/self&userId=1&cityId=1&venueId=${this.$route.query.venueId || '31'}&isUsedCoupon=${this.isUsedCoupon}`

            let payData = {
                subject: info.equityCardItemName,
                extendsJson: {
                    appVersion: '2.1.11',
                    seriesName: info.equityCardItemName,
                    ticketId: this.checkOrder? this.ticketId: undefined,
                    ticketSource: this.ticketSource,
                    discountId: this.selectedCouponId,
                    giveUpFirstBuy: this.giveUpFirstBuy
                },
                orderEquityCardCreateReq: {
                    source: 'SELF_BUY'
                },
                totalAmount: (this.giveUpFirstBuy || !info.firstPrice)? info.salePrice: info.firstPrice,
                firstPrice: info.firstPrice,
                venueId: this.currentVenueId || '31',
                goodsNum: "1",
                isApp: '1',
                type: '14',
                selectedDiscount: this.selectedDiscount,
                discountId: this.selectedCouponId,
                userId: this.userId,
                token: this.token,
                cityId: this.currentCityId,
                extendsType: '4',
                mouldId: info.equityCardItemId,
                whetherPrivacy: 0,
                originalPriceStr:info.originalPrice,
                title: '购买内容',
            }
            if(this.appTypeStr === 'mini'){
                payData.wxPayType = 'applet'
            }
            // 安卓判断是否支持
            if(!this.canIUseSilentAppPay && this.appTypeStr === 'and'){
                payData.totalAmount = this.salePrice
                appPay(payData, url)
            }else{
                this.showPayPopup = true
                this.payData = payData
                this.payUrl = url
            }
            if( this.appTypeStr !== 'mini' ){
                appPaySuccess().then(res=>{
                    this.showPaySuccess = true
                    this.getCardList();
                })
            }
        },
        jumpMyBagCopy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/my-card&userId=1&token=1`
                })
            } else {
                this.$router.push({
                    path: '/month-card/my-card',
                })
            }
        },
        jumpSendGift() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/give-equity-card&userId=1&token=1&showList=${true}`,
                })
            } else {
                this.$router.push(`/give-equity-card?showList=${true}`)
            }
        },
        /**
         * 小程序广告转化
         * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
         */
        addUserAction(actionType)  {
            if(!this.clickId){
                return false
            }
            this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`,{
                actionUrl: 'superdeer-activity/sell-membership/self',
                clickId: this.clickId,
                actionType: actionType
            }, false).then(res => {
                console.log('广告数据转化埋点：类型',actionType,'返回参数',res)
            }).catch(r => {
                console.log('广告数据转化埋点：类型',actionType,'埋点接口调用失败')
            })
        },
        isEmpty(data) {
            // console.log(typeof data)
            switch (typeof data){
                case "number":
                    return data === 0
                case "object":
                    return (!data || Object.keys(data).length < 1);
                case "undefined":
                    return true
                case "boolean":
                    return false
                case "string":
                    return (data === 'undefined' || data === 'null' || data === '' || data === '0')
            }
        },
    },
};
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}
.new-card-list{
    display: flex;
    flex-wrap: wrap;
    padding:0 32px 6px;
    .card-item{
        width: 334px;
        height: 186px;
        box-sizing: border-box;
        background: #F9FAFB;
        border-radius: 16px;
        border: 2px solid #ddd;
        flex-shrink: 0;
        margin-bottom: 18px;
        position: relative;
        padding: 32px 28px 0;
        .first-price{
            text-align: right;
            line-height: 20px;
            font-size: 20px;
            margin-top: 12px;
            color: #FF6E00;
        }
        &:nth-child(2n){
            margin-left: 18px;
        }
        &.active{
            background-color: #FFFDE3;
            border-color: #FFDE00;
            .couponAfterPrice{
                background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
            }
            .first-price{
                color: #A56309;
            }
            .item-tips{
                color: #A56309;
            }
        }
        .card-item-top{
            margin-bottom: 12px;
        }
        .card-name{
            color: #242831;
            font-size: 26px;
            font-weight: bold;
            line-height: 26px;
        }
        .item-tips{
            font-size: 20px;
            color: #6C727A;
            line-height: 20px;
            max-width: 8em;
        }
        .price {
            font-size: 64px;
            position: relative;
            top: 10px;
            color: #242831;
            //font-weight: bold;
            white-space: nowrap;
            font-family: BebasNeueBold;
            span {
                font-size: 28px;
            }
        }
        .originalPrice{
            font-size: 18px;
            text-decoration:line-through;
            color: #6C727A;
            font-weight: normal;
            line-height: 20px;
            white-space: nowrap;
        }
        .couponAfterPrice{
            height: 36px;
            background-color: #ECC589;
            border-radius: 8px;
            color: #A56309;
            font-size: 22px;
            font-weight: bold;
            padding: 0 8px;
            //margin-bottom: 12px;
        }
        .discount-tag {
            position: absolute;
            right: -2px;
            top: -10px;
            height: 30px;
            background: #ED5C42;
            border-radius: 4px;
            font-size: 18px;
            color: #FFFFFF;
            padding: 0 8px;
            box-sizing: border-box;
        }
    }
}
.bottom-rule{
    padding: 48px 32px 62px;
    h4{
        line-height: 36px;
        font-size: 36px;
        color: #232831;
    }
    p{
        margin-top: 14px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: #3C454E;
        white-space: pre-line;
    }
}
.refund-box{
    width: 750px;
    background: #FFFFFF;
    border-radius: 12px;
    .pay-img{
        padding-top: 56px;
        img{
            width: 100%;
            display: block;
        }
    }
    .refund-title{
        padding: 32px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 34px;
        line-height: 48px;
        color: #242831;
    }
    .refund-content{
        padding: 0 32px;
        font-size: 28px;
        line-height: 44px;
        color: #9AA1A9;
        word-wrap: break-word;
        word-break: break-all;
    }
    .refund-info{
        margin-top: 30px;
        font-size: 28px;
        line-height: 28px;
        color: #242831;
        padding: 0 32px;
        span:last-child{
            font-weight: bold;
        }
    }
    .refund-btn{
        margin-top: 40px;
        padding: 28px 32px;
        border-top: 1px solid #eee;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            width: 100%;
            font-size: 28px;
            border-radius: 12px;
            border: 1px solid #eee;
            color: #242831;
            box-sizing: border-box;
            &:first-child{
                font-weight: bold;
                background-color: #FFDE00;
                border-color: #FFDE00;
            }
        }
    }
}
.page-box {
    min-height: 100vh;
    padding-bottom: 300px;
    & > div {
        width: 750px;
    }
    .top-menu{
        padding-left: 32px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: white;
        height: 66px;
        width: 750px;
        margin-bottom: 32px;
        div{
            span{
                font-size: 40px;
                color: #3C454E;
                font-weight: 500;
                margin-right: 6px;
            }
            .play-icon{
                width: 32px;
                height: 32px;
                transform: rotateZ(90deg);
                transition: all .3s;
                transform-origin: center center;
                font-size: 24px;
                &.active{
                    transform: rotateZ(-90deg);
                    right: 0;
                }
            }

        }
    }
    .page-h {
        position: relative;
        width: 750px;
        background: #fff;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 0 0 24px;
        &>div{
            width: 686px;
            height: 360px;
            border-radius: 16px;
            background:#fff  no-repeat center center;
            background-size: 100% 100%;
            padding-bottom: 32px;
            box-sizing: border-box;
            div{
                padding: 32px 0 0 32px;
                font-size: 60px;
                //font-weight: bold;
                color: white;
                font-family: PuHuiTi;
            }
            p{
                font-size: 18px;
                color: #FFFFFF;
                line-height: 32px;
                margin: 0 0 0 32px;
                &>span{
                    background-color: rgba(3,3,3,0.2);
                    padding: 8px;
                    border-radius: 6px;
                }
            }
        }
    }
    .voucher{
        padding: 16px 32px 0;
        div{
            padding: 0 4px 0 120px;
            height: 40px;
            border-radius: 0 2px 2px 0;
            border: 1px solid #F03C18;
            border-left: none;
            font-size: 24px;
            color: #F03C18;
            font-weight: bold;
            background: url("https://img.chaolu.com.cn/MINI/icon/voucher-icon1.png") no-repeat left center;
            background-size: 104px 40px;
            box-sizing: border-box;
            span{
                margin-right: 4px;
            }
        }
    }
    .card-box {
        background-color: white;
        &.bottom-btn {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 750px;
            padding: 0 0 calc(12px + env(safe-area-inset-bottom));
            z-index: 999;
        }
        .order-card-tips{
            width: 686px;
            height: 70px;
            background: #ECF3FE;
            color: #2D7EF5;
            font-size: 22px;
            border-radius: 16px;
            line-height: 70px;
            margin: 32px auto;
            padding-left: 24px;
        }
        .card-tips {
            font-size: 22px;
            line-height: 34px;
            color: #3C454E;
            padding: 0 32px;
            text-align: justify;
            word-break: break-all;
        }

        .card-list {
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding:16px 32px 32px;
            box-sizing: border-box;
            width: 100vw;
            &::-webkit-scrollbar {
                display: none;
            }

            .card-item {
                height: 296px;
                position: relative;
                width: 224px;
                background: #F9FAFB;
                border-radius: 16px;
                border: 2px solid #DDDDDD;
                flex-shrink: 0;
                box-sizing: border-box;
                &.card-item + .card-item {
                    margin-left: 16px;
                }
                &.active {
                    background: #FFFDE3;
                    border-color: #FFDE00;

                    & > p {
                        font-weight: 600;
                        background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
                        color: #242831;
                    }
                    .item-tips{
                        color: #A56309;
                    }
                }
                .item-tips{
                    font-size: 20px;
                    line-height: 20px;
                    color: #6C727A;
                    margin-top: 20px;
                }
                .discount-tag {
                    position: absolute;
                    right: -2px;
                    top: -10px;
                    height: 30px;
                    background: #ED5C42;
                    border-radius: 4px;
                    font-size: 18px;
                    color: #FFFFFF;
                    padding: 0 8px;
                    box-sizing: border-box;
                }
                & > div {
                    height: 256px;
                    width: 100%;
                    padding: 40px 12px 0;
                    box-sizing: border-box;
                    p {
                        font-size: 28px;
                        color: #242831;
                        font-weight: bold;
                        word-break: break-all;
                        text-align: justify;
                        line-height: 32px;
                        height: 64px;
                    }
                    .price {
                        margin-top: 6px;
                        font-size: 56px;
                        color: #242831;
                        font-weight: bold;
                        font-family: BebasNeueBold;
                        span {
                            font-size: 28px;
                        }
                    }
                    .originalPrice{
                        font-size: 22px;
                        text-decoration:line-through;
                        color: #6C727A;
                        font-weight: normal;
                    }
                }
                &>p {
                    background: #ECC589;;
                    color: #A56309;
                    font-weight: bold;
                    font-size: 24px;
                    height: 44px;
                    width: 224px;
                    position: absolute;
                    left: -2px;
                    bottom: -2px;
                    border-radius: 0 0 16px 16px;
                }
            }
        }
        .select-voucher{
            width: 100vw;
            height: 72px;
            background: #FDEEEC;
            margin: 0 auto 0;
            padding: 0 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                height: 32px;
                margin-right: 12px;
            }
            &>div{
                font-size: 24px;

                span{
                    color: #F03C18;
                    //font-weight: bold;
                }
            }
            &>p{
                display: flex;
                align-items: center;
                font-size: 24px;
                //color: #F03C18;
                span{
                    margin-left: 4px;
                }
            }
        }
        .buy-card-btn {
            width: 686px;
            height: 112px;
            margin: 32px auto 20px;
            //background-color: #242831;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            position: relative;
            border-radius: 24px;
            background-image: url("https://img.chaolu.com.cn/ACT/double12-2022/index/buy-button.png");
            &.send-bg{
                background-image: url("https://img.chaolu.com.cn/ACT/schedule-2023/btn_img.png");
            }
            &>h4 {
                font-size: 64px;
                color: #F7DFB4;
                width: 360px;
                padding-left: 80px;
                box-sizing: border-box;
                font-weight: bold;
                position: relative;
                top: 2px;

                .s1 {
                    font-family: BebasNeueBold;
                    font-weight: normal;
                    font-size: 32px;
                }

                .s2 {
                    font-family: BebasNeueBold;
                }

                .s3 {
                    font-weight: normal;
                    color: #FFFFFF;
                    font-size: 24px;
                    margin-left: 12px;
                    text-decoration: line-through;
                }
            }

            &>div {
                display: flex;

                //padding-right: 8px;
                p {
                    width: 314px;
                    height: 112px;
                    //background: linear-gradient(90deg, #FFEF84 0%, #FFDE00 100%);
                    border-radius: 22px;
                    font-size: 32px;
                    font-weight: bold;
                    color: #242831;

                    &:last-child {
                        margin-left: 12px;
                    }
                }
            }

            &.purchased {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button1.png");
            }

            &.no-start {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button3.png");
            }

            &.end {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button2.png");
            }
        }

        .protocol {
            display: flex;
            align-items: flex-start;
            padding: 0 32px;

            &.shake {
                animation: shake 1s;
            }

            p {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                background: url("https://img.chaolu.com.cn/ACT/invite-2022/icon7.png") no-repeat center center;
                background-size: 100% 100%;

                &.active {
                    background-image: url("https://img.chaolu.com.cn/ACT/invite-2022/icon6.png");
                }
            }

            div {
                font-size: 18px;
                line-height: 26px;
                color: #707072;
                padding-top: 7px;

                .s1 {
                    font-weight: bold;
                    color: #232831;
                }
            }
        }
        .gift-detail{
            margin-top: 32px;
            .gift-detail-top{
                background: #2A2D3C;
                padding: 48px 32px 20px;
                h4{
                    font-size: 28px;
                    line-height: 28px;
                    color: #FFFFFF;
                    margin-bottom: 32px;
                    text-align: center;
                }
                &>div{
                    flex-wrap: wrap;
                    div{
                        position: relative;
                        margin-bottom: 20px;
                        margin-right: 16px;
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                        span{
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 34px;
                            padding: 0 18px;
                            background: #FFDE00;
                            border-radius: 12px 0 12px 0;
                            color: #242831;
                            font-size: 20px;
                        }
                        img{
                            width: 218px;
                            height: 196px;
                            border-radius: 12px;
                            display: flex;
                        }
                        p{
                            width: 218px;
                            font-size: 20px;
                            line-height: 28px;
                            color: #FFFFFF;
                            font-weight: 500;
                            margin-top: 18px;
                        }
                    }
                }
            }
            .gift-detail-bottom{
                background: #F5F5F5;
                padding: 48px 32px;
                h4{
                    font-size: 36px;
                    line-height: 36px;
                    color: #232831;
                }
                &>p{
                    font-size: 24px;
                    color: #3C454E;
                    line-height: 24px;
                    margin-top: 20px;
                }
                .gift-detail-item{
                    width: 686px;
                    height: 332px;
                    background: #FFFFFF;
                    border-radius: 16px;
                    margin-top: 32px;
                    position: relative;
                    .gift-sort{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 106px;
                        height: 52px;
                        background: #FFE11C;
                        border-radius: 16px 0 16px 0;
                        color: #232831;
                        font-weight: 500;
                        font-size: 22px;
                    }
                    img{
                        width: 100%;
                        border-radius: 16px 0 0 16px;
                    }
                    &>div{
                        padding: 64px 36px 0;
                        div{
                            font-weight: bold;
                            line-height: 32px;
                            color: #232831;
                            font-size: 32px;
                            .gift-question{
                                font-size: 32px;
                            }
                            span{
                                margin-right: 10px;
                            }
                        }
                        p{
                            margin-top: 24px;
                            color: #6C727A;
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }

}
.protocol-box{
    width: 630px;
    height: 900px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    h4{
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }
    p{
        &::-webkit-scrollbar{
            display: none;
        }
        height: 570px;
        overflow-x: auto;
        text-align: left;
        margin: 32px  auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
        white-space: pre-line;
    }
    div{
        margin: 0  auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
        &.gray{
            background: #9AA1A9;
        }
    }
}
.error-box{
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    padding-bottom: 48px;
    &>span{
        display: block;
        text-align: center;
        margin-bottom: 24px;
        font-size: 28px;
        font-weight: bold;
        color: #0a7aff;
    }
    h4{
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }
    p{
        text-align: center;
        margin: 32px  auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
    }
    div{
        margin: 0  auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
    }
}
// 支付成功弹窗
.pay-box {
    width: 590px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
    background-size: 590px 554px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 40px;

    .pay-box-title {
        img {
            width: 128px;
            height: 128px;
            display: block;
            margin: 0 auto;
            padding: 64px 0 48px;
        }

        div {
            font-weight: bold;
            color: #242831;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
    }

    .pay-box-text {
        font-size: 28px;
        color: #242831;
        line-height: 40px;
        margin-top: 24px;
        text-align: center;
    }

    .pay-box-tips {
        font-size: 22px;
        color: #6C727A;
        line-height: 26px;
        text-align: center;
        width: 460px;
        margin: 40px auto 0;
        .s1 {
            color: #FF6E00;
            font-size: 22px;
        }
    }

    .pay-share-btn {
        div{
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            border-radius: 8px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            &:last-child{
                font-weight: bold;
                background: #FFDE00;
                border-color: #FFDE00;
            }
        }
        width: 510px;
        height: 96px;
        //display: block;
        margin: 128px auto 0;
        //background: #FFDE00;
        //font-weight: bold;
        color: #242831;
        //font-size: 32px;
        //line-height: 96px;
        //text-align: center;
        //border-radius: 8px;
    }
}

.result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
}
// 海报样式
.share-poster-cont {
    position: relative;
    overflow: hidden;
    //width: 100 * @w;
    width: 654px;
    height: 968px;
    //width: 103.2vw;
    margin: 0 auto;
    box-sizing: border-box;

    img.share-bg {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.share-user {
    position: absolute;
    left: 32px;
    top: 32px;
    display: flex;
    align-items: center;

    img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin-right: 24px;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
    }

    .use-header-name {
        font-size: 28px;
        font-weight: bold;
        color: #242831;
        line-height: 40px;
        //width: 45 * @w;
        width: 15em;
    }

    .use-header-text {
        font-size: 22px;
        font-weight: 400;
        color: #242831;
        line-height: 26px;
        margin-top: 12px;
    }
}

.share-poster-cont .poster-code-cont {
    position: absolute;
    right: 32px;
    bottom: 32px;
    width: 160px;
    height: 160px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.35);;
    //box-sizing: border-box;
    border-radius: 16px;
}

.share-poster-cont .poster-code-img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: block;
    background-color: white;
    box-sizing: border-box;

}
.city-nav-box {
    display: flex;
    align-items: center;
    padding: 16px 32px 24px;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: auto;
    background-color: #fff;

    div {
        //width: 148px;
        flex-shrink: 0;
        height: 56px;
        background: #fff;
        border-radius: 8px;
        color: #666666;
        font-size: 22px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #999999;
        margin-right: 24px;
        box-sizing: border-box;

        &.active {
            font-weight: bold;
            color: #242831;
            border-color: #242831;
        }
    }
}
.first-price-box{
    width: 750px;
    height: 64px;
    display: flex;
    align-items: center;
    line-height: 23px;
    font-size: 23px;
    color: #267DFF;
    padding-left: 76px;
    box-sizing: border-box;
    background: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_select.png") no-repeat #ECF3FE 32px center;
    background-size: 40px 40px;
    &.active{
        background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_selected.png");
    }
}
</style>
